import { useState } from 'react';

import { twMerge } from 'tailwind-merge';

import BuyAitechModal from '@features/marketplace/buyAitech/BuyAitechModal';
import { useUserLoginModal } from '@features/user/provider/context';

interface IProps {
  className?: string;
  buttonClassName?: string;
  isUserLogin?: boolean;
}

const BuyAitechButton = ({
  className,
  buttonClassName,
  isUserLogin = false,
}: IProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const { openUserLogin } = useUserLoginModal();

  return (
    <div className={className}>
      <div>
        <button
          className={twMerge(
            'animatedBorderButton label-small roundedBlTr flexCenter relative z-[1] h-10 w-fit shrink-0 cursor-pointer overflow-hidden px-3 font-bold',
            isOpen ? 'continueAnimation' : '',
            buttonClassName
          )}
          onClick={() => {
            if (isUserLogin) {
              setIsOpen(true);
              return;
            }
            openUserLogin(); // open login modal
          }}
        >
          Buy AITECH
        </button>
      </div>

      {isOpen && <BuyAitechModal isOpen={isOpen} setIsOpen={setIsOpen} />}
    </div>
  );
};

export default BuyAitechButton;
