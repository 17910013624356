'use client';

import { usePathname } from 'next/navigation';
import { useCallback, useEffect } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { twMerge } from 'tailwind-merge';
import { useAccount, useSignMessage } from 'wagmi';

import useCurrentUser from '@api/user/auth/queries/useCurrentUser';
import useUserConnectWalletMutation from '@api/user/connectWallet/mutations/useUserConnectWalletMutation';
import { userConnectWalletApi } from '@api/user/connectWallet/userConnectWalletApi';
import { useGetPurchaseInfo } from '@api/user/profile/queries/usePurchaseInfoQuery';
import useReadUserBalanceEvent from '@api/userSocket/read/useReadUserBalanceEvent';

import Banners from '@components/banners';
import Button from '@components/button';
import { getProductUrlSeo } from '@components/productCard/ProductCard';
import UserActionsDropdown from '@components/profileActionsDropdown/UserActionsDropdown';
import Search from '@components/search';
import ToastMaintenance from '@components/toast/ToastMaintenance';

import ProductNavActions from '@features/admin/ProductManagement/ProductNavActions/ProductNavActions';
import SubmissionNavActions from '@features/admin/ProductSubmissions/SubmissionNavActions/SubmissionNavActions';
import NotificationsButton from '@features/forum/notificationsButton/NotificationsButton';
import BuyAitechButton from '@features/marketplace/buyAitech/BuyAitechButton';
import { ConnectButton } from '@features/profile/layout/profileInformation/userData/UserData';
import { useDepositModal } from '@features/profile/layout/profileStats/depositFundsModal/provider/context';
import { useUserLoginModal } from '@features/user/provider/context';

import { LogoSvg } from '@shared/assets';
import config from '@shared/config';
import { USER_ME } from '@shared/constants';
import { useProductActionsContext } from '@shared/contexts/ProductActionsContext';
import { useSearchQuery } from '@shared/hooks';
import { PRODUCTS } from '@shared/utils';

import InsufficientBalanceWarning from './InsufficientBalanceWarning';
import MarketplaceMenuWrapper from './MarketplaceMenuWrapper';
import { NAVIGATION_ITEMS } from './constants';

const MarketplaceNavigation = () => {
  const pathname = usePathname();
  const { openUserLogin } = useUserLoginModal();
  const { triggerModal } = useDepositModal();

  const { searchQuery, setSearchQuery, handleSearch } = useSearchQuery();

  const { data: userInfo } = useCurrentUser();
  const { data: purchaseInfo } = useGetPurchaseInfo();
  const { address } = useAccount();
  const queryClient = useQueryClient();

  const { signMessageAsync, isPending } = useSignMessage();
  const { mutate: connectWalletMutation } = useUserConnectWalletMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: USER_ME,
      });
    },
  });

  const { productToApprove, productToSetVisibility } =
    useProductActionsContext();

  useReadUserBalanceEvent();

  const shouldDisplayApproveOptions =
    productToApprove &&
    pathname ===
      `/${getProductUrlSeo(productToApprove.productType?.name, productToApprove.id, productToApprove.name)}`;

  const shouldDisplayVisibilityOptions =
    productToSetVisibility &&
    pathname ===
      `/${getProductUrlSeo(productToSetVisibility.productType?.name, productToSetVisibility.id, productToSetVisibility.name)}`;

  const shouldDisplayReviewOptions =
    shouldDisplayApproveOptions || shouldDisplayVisibilityOptions;

  const shouldShowMaintenanceToast = config.maintenanceModeWarning;

  const handleChatSessionStorage = () => {
    if (pathname.startsWith(PRODUCTS)) {
      const productId = pathname.split('/');
      sessionStorage.removeItem(`chat-open-${productId[2]}`);
    }
  };

  const registerWallet = useCallback(async () => {
    try {
      if (!address) return;
      const signMessage = await userConnectWalletApi.client.getSigninMessage({
        walletAddress: address,
      });
      if (signMessage?.data && !isPending) {
        await signMessageAsync(
          { message: signMessage?.data?.message },
          {
            onSuccess: (signature) => {
              connectWalletMutation({
                signature,
              });
            },
          }
        );
      }
    } catch (error) {}
  }, [address, isPending, connectWalletMutation, signMessageAsync]);

  useEffect(() => {
    if (
      (!!address &&
        !!userInfo?.data?.walletAddress &&
        address !== userInfo?.data?.walletAddress) ||
      (!!address && !userInfo?.data?.walletAddress)
    ) {
      registerWallet();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo?.data?.walletAddress, address]);

  return (
    <>
      <Banners />
      <div
        className={twMerge(
          'sticky top-0 z-99 border-b border-t border-neutral700 bg-neutral1000'
        )}
      >
        <nav
          className={twMerge(
            'relative top-0 z-99 m-auto flex h-16 w-full max-w-[1750px] items-center justify-end gap-4 md:justify-between lg:pl-4 xl:pr-4'
          )}
        >
          <a
            href={'/'}
            onClick={handleChatSessionStorage}
            className="flexCenter ml-3 max-w-40 shrink-0 text-neutral300 max-xl:flex-[2] xl:h-10"
          >
            <LogoSvg
              width={160}
              height={40}
              className={twMerge('size-full overflow-visible')}
            />
          </a>
          <div className="flex size-full items-center justify-end gap-4 max-xl:flex-1 md:justify-between">
            {shouldDisplayReviewOptions ? (
              <>
                {shouldDisplayApproveOptions && (
                  <SubmissionNavActions product={productToApprove} />
                )}
                {shouldDisplayVisibilityOptions && (
                  <ProductNavActions product={productToSetVisibility} />
                )}
              </>
            ) : (
              <>
                <Search
                  placeholder={'Search AI products by name or publisher'}
                  className="order-1 min-w-28 flex-1 bg-neutral800 focus-within:bg-neutral800 hover:bg-neutral800 max-md:hidden"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  noMobile
                  onSearch={handleSearch}
                />
                <MarketplaceMenuWrapper
                  items={NAVIGATION_ITEMS}
                  className="flex-2 order-3 lg:px-0"
                  breakpoint="xl"
                  isLoggedIn={!!userInfo?.data}
                />
              </>
            )}
            <div className="order-1 flex h-full items-center xl:order-3">
              {
                <BuyAitechButton
                  className="mr-2.5 max-xl:hidden"
                  isUserLogin={!!userInfo?.data}
                />
              }
              {userInfo?.data ? (
                <div className="mr-2.5 flex items-center gap-2.5 max-xl:hidden">
                  {address && (
                    <Button
                      onClick={() => triggerModal()}
                      className="label-small md:flexCenter relative z-[1] hidden h-10 w-fit shrink-0 cursor-pointer overflow-hidden px-3 max-xl:!hidden"
                    >
                      Deposit
                    </Button>
                  )}
                  <ConnectButton />
                </div>
              ) : null}

              {userInfo?.data && <NotificationsButton />}

              {userInfo?.data ? (
                <UserActionsDropdown />
              ) : (
                <Button
                  // variant={BUTTON_VARIANT.SECONDARY}
                  onClick={openUserLogin}
                >
                  Login / Signup
                </Button>
              )}
            </div>
          </div>
        </nav>
        {shouldShowMaintenanceToast && <ToastMaintenance disabledClose />}
      </div>
      {purchaseInfo?.data?.isWarningLowBalance && (
        <InsufficientBalanceWarning />
      )}
    </>
  );
};

export default MarketplaceNavigation;
