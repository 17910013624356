import { useQuery } from '@tanstack/react-query';

import { HOME_BANNERS } from '@shared/constants/queryKeys';

import { marketplaceApi } from '../marketplaceApi';
import { useMemo } from 'react';
import { IHomeBanner } from '@interfaces/admin/homeBanners';

export const useGetHomeBanners = () => {
  const { data, ...restData } = useQuery({
    queryKey: [HOME_BANNERS],
    queryFn: () => marketplaceApi.client.getHomeBanners(),
  });
  return {
    data: useMemo(() => data?.data || ([] as IHomeBanner[]), [data?.data]),
    ...restData
  };
}

