import { ResponseWrapper, StatisticDataResponse } from '@interfaces';
import {
  FeaturedProductsResponseData,
  ProductFiltersResponse,
  ProductTypeResponse,
  UserProductListParams,
  UserProductListResponse,
} from '@interfaces/marketplace/products';

import {
  withAuthenticatedClientRequest,
  withClientRequest,
} from '@api/requestBuilder/client/withClientRequest';
import {
  withAuthenticatedServerRequest,
  withServerRequest,
} from '@api/requestBuilder/server/withServerRequest';
import { ICallableRequestBuilder } from '@api/requestBuilder/types';

import config from '@shared/config';
import { generateUrlParams } from '@shared/utils/urlString';
import { ITrendingToken } from '@interfaces/admin/trendingTokens';
import { IHomeBanner } from '@interfaces/admin/homeBanners';

const getProductList =
  (request: ICallableRequestBuilder<UserProductListResponse>) =>
    async (params?: UserProductListParams) => {
      const paramString = generateUrlParams(params);

      return request.call(
        `${config.userApiUrl}/api/v1/marketplaces/products?${paramString}&limit=12`
      );
    };

const getProductCategories =
  (request: ICallableRequestBuilder<ProductFiltersResponse>) =>
    async (productTypeId: number) => {
      return request.call(
        `${config.userApiUrl}/api/v1/marketplaces/categories/${productTypeId}`
      );
    };

const getMarketplaceTags =
  (request: ICallableRequestBuilder<ProductFiltersResponse>) => async () => {
    return request.call(`${config.userApiUrl}/api/v1/marketplaces/tags`);
  };

const getProductTypes =
  (request: ICallableRequestBuilder<ProductTypeResponse>) => async () => {
    const paramString = generateUrlParams({
      page: 1,
      limit: 10,
      sortBy: 'createdAt:ASC',
    });
    return request.call(
      `${config.userApiUrl}/api/v1/marketplaces/product-types?${paramString}`
    );
  };

const getFeaturedProducts =
  (request: ICallableRequestBuilder<FeaturedProductsResponseData>) =>
    async () => {
      return request.call(
        `${config.userApiUrl}/api/v1/marketplace/featured-products`
      );
    };

const getHomepageStatistic =
  (request: ICallableRequestBuilder<StatisticDataResponse>) => async () => {
    return request.call(
      `${config.userApiUrl}/api/v1/marketplaces/homepage/statistic`
    );
  };

const getTrendingTokens =
  (request: ICallableRequestBuilder<ResponseWrapper<ITrendingToken[]>>) => async () => {
    return request.call(`${config.userApiUrl}/api/v1/user/trending-tokens`);
  };

const getHomeBanners =
  (request: ICallableRequestBuilder<ResponseWrapper<IHomeBanner[]>>) => async () => {
    return request.call(`${config.userApiUrl}/api/v1/user/home-banners`);
  };

export const marketplaceApi = {
  client: {
    getProductsList: withAuthenticatedClientRequest(getProductList),
    getBookmarkedProducts: withAuthenticatedClientRequest(getProductList),
    getProductCategories: withClientRequest(getProductCategories),
    getMarketplaceTags: withClientRequest(getMarketplaceTags),
    getProductTypes: withClientRequest(getProductTypes),
    getFeaturedProducts: withClientRequest(getFeaturedProducts),
    getHomepageStatistic: withClientRequest(getHomepageStatistic),
    getTrendingTokens: withClientRequest(getTrendingTokens),
    getHomeBanners: withClientRequest(getHomeBanners),
  },
  server: {
    getProductsList: withAuthenticatedServerRequest(getProductList),
    getBookmarkedProducts: withAuthenticatedServerRequest(getProductList),
    getProductCategories: withServerRequest(getProductCategories),
    getMarketplaceTags: withServerRequest(getMarketplaceTags),
    getProductTypes: withServerRequest(getProductTypes),
    getFeaturedProducts: withServerRequest(getFeaturedProducts),
    getHomepageStatistic: withServerRequest(getHomepageStatistic),
    getTrendingTokens: withServerRequest(getTrendingTokens),
    getHomeBanners: withServerRequest(getHomeBanners),
  },
};
