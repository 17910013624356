import useGetUserFeeSettings from '@api/user/settings/queries/useGetUserFeeSettings';

import { formatDisplayCurrency } from '@features/utils/numbers';

const useCalculateUserUSDAIPrice = (price?: string | number) => {
  const { totalFee } = useGetUserFeeSettings();

  const getListingPrice = (price: string | number) => {
    return formatDisplayCurrency(Number(price) / (1 - totalFee));
  }

  return {
    userPrice: price && +price > 0 ? getListingPrice(price) : '0',
    getListingPrice
  };
};

export default useCalculateUserUSDAIPrice;
