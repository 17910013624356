'use client';

import useDisclosure from '@shared/hooks/useDisclosure';

import UserLoginAuth from '../userAuth/login/UserLoginAuth';
import { UserLoginModalContext } from './context';

export interface Props {
  children: React.ReactNode;
}

export const UserLoginModalProvider = ({ children }: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <UserLoginModalContext.Provider
      value={{ openUserLogin: onOpen, closeUserLogin: onClose }}
    >
      {children}
      {isOpen && (
        <UserLoginAuth isOpen={isOpen} onClose={onClose} onOpen={onOpen} />
      )}
    </UserLoginModalContext.Provider>
  );
};
