import {
  PaginatedResponseWrapper,
  ResponseWrapper,
  ReviewListParams,
} from '@interfaces';
import { BookmarkResponse } from '@interfaces/products/productBookmark';
import { UserGetProductsDetailResponse } from '@interfaces/products/productDetails';
import {
  GetProductReviewListResponse,
  GetTotalReviewByStarResponse,
  UserReviewProductData,
} from '@interfaces/products/productReviews';
import {
  IRefundRequest,
  IRejectRefundRequestData,
} from '@interfaces/publisher/refundRequests';
import queryString from 'query-string';

import {
  withAuthenticatedClientRequest,
  withClientRequest,
} from '@api/requestBuilder/client/withClientRequest';
import {
  withAuthenticatedServerRequest,
  withServerRequest,
} from '@api/requestBuilder/server/withServerRequest';
import { ICallableRequestBuilder } from '@api/requestBuilder/types';

import config from '@shared/config';

const getProductDetails =
  (request: ICallableRequestBuilder<UserGetProductsDetailResponse>) =>
    async (productId: number) => {
      return request.call(
        `${config.userApiUrl}/api/v1/marketplaces/products/${productId}`
      );
    };

const putProductView =
  (request: ICallableRequestBuilder<ResponseWrapper>) =>
    async (productId: number) => {
      return request.call(
        `${config.userApiUrl}/api/v1/marketplaces/products/${productId}/view`,
        (init) => ({
          ...init,
          method: 'PUT',
        })
      );
    };

const getProductTotalRating =
  (request: ICallableRequestBuilder<GetTotalReviewByStarResponse>) =>
    async (productId: number) => {
      return request.call(
        `${config.userApiUrl}/api/v1/marketplaces/products/${productId}/total-review-group-by-star`
      );
    };

const getProductReviews =
  (request: ICallableRequestBuilder<GetProductReviewListResponse>) =>
    async (productId: number, params: ReviewListParams) => {
      const url = queryString.stringifyUrl({
        url: `${config.userApiUrl}/api/v1/marketplaces/products/${productId}/reviews`,
        query: { ...params },
      });

      return request.call(url);
    };

const bookmark =
  (request: ICallableRequestBuilder<BookmarkResponse>) =>
    (productId: number): Promise<BookmarkResponse> => {
      return request.call(
        `${config.userApiUrl}/api/v1/user/products/${productId}/bookmark`,
        (init) => ({
          ...init,
          method: 'POST',
        })
      );
    };

const unBookmark =
  (request: ICallableRequestBuilder<BookmarkResponse>) =>
    (productId: number): Promise<BookmarkResponse> => {
      return request.call(
        `${config.userApiUrl}/api/v1/user/products/${productId}/unbookmark`,
        (init) => ({
          ...init,
          method: 'POST',
        })
      );
    };

const writeReview =
  (request: ICallableRequestBuilder<ResponseWrapper>) =>
    (
      productId: number,
      data: UserReviewProductData
    ): Promise<ResponseWrapper> => {
      // TODO: Connect endpoint to leave review
      return request.call(
        `${config.userApiUrl}/api/v1/user/products/${productId}/review`,
        (init) => ({
          ...init,
          method: 'POST',
          headers: {
            ...init.headers,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        })
      );
    };

const submitRefundRequest =
  (
    request: ICallableRequestBuilder<PaginatedResponseWrapper<IRefundRequest[]>>
  ) =>
    async (data: IRejectRefundRequestData, purchasedId?: number) => {
      const body = {
        ...data,
        userPurchaseId: purchasedId,
      };

      return request.call(
        `${config.userApiUrl}/api/v1/user/refund-requests`,
        (init) => ({
          ...init,
          method: 'POST',
          headers: {
            ...init.headers,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body),
        })
      );
    };

const submitRefundRequestToAdmin =
  (request: ICallableRequestBuilder<ResponseWrapper>) =>
    async (refundRequestId?: number) => {
      return request.call(
        `${config.userApiUrl}/api/v1/user/refund-requests/${refundRequestId}/request-to-admin`,
        (init) => ({
          method: 'PUT',
          headers: {
            ...init.headers,
            'Content-Type': 'application/json',
          },
          ...init,
        })
      );
    };

const openIframe =
  (request: ICallableRequestBuilder<{ productId: number }>) =>
    (productId: number): Promise<{ productId: number }> => {
      return request.call(
        `${config.userApiUrl}/api/v1/user/products/${productId}/open-iframe`,
        (init) => ({
          ...init,
          method: 'PATCH',
        })
      );
    };

export const productApi = {
  client: {
    getProductDetails: withAuthenticatedClientRequest(getProductDetails),
    getProductTotalRating: withClientRequest(getProductTotalRating),
    getProductReviews: withClientRequest(getProductReviews),
    bookmark: withAuthenticatedClientRequest(bookmark),
    unBookmark: withAuthenticatedClientRequest(unBookmark),
    writeReview: withAuthenticatedClientRequest(writeReview),
    submitRefundRequest: withAuthenticatedClientRequest(submitRefundRequest),
    submitRefundRequestToAdmin: withAuthenticatedClientRequest(
      submitRefundRequestToAdmin
    ),
    openIframe: withAuthenticatedClientRequest(openIframe),
  },
  server: {
    getProductDetails: withAuthenticatedServerRequest(getProductDetails),
    getProductTotalRating: withServerRequest(getProductTotalRating),
    getProductReviews: withServerRequest(getProductReviews),
    putProductView: withServerRequest(putProductView),
  },
};
