import { createContext, useContext } from 'react';

import { UserLoginModalContextType } from './types';

const UserLoginModalContext = createContext<UserLoginModalContextType>({
  openUserLogin: () => { },
  closeUserLogin: () => { },
});

const useUserLoginModal = () => useContext(UserLoginModalContext);

export { UserLoginModalContext, useUserLoginModal };
